import $ from '@vaersaagod/tools/Dom';

export default (el, props) => {
    const dom = $(el);
    const field = dom.find('[data-field]').get(0);

    const init = () => {
        field.focus();
        field.select();
    };

    const destroy = () => {};

    return {
        init,
        destroy
    };
};
