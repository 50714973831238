import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import * as Events from '../lib/events';

const OFFSET_TOP_UP = 0;
const OFFSET_TOP_DOWN = 50;
const OFFSET_BUFFER = 20;

export default el => {
    const dom = $(el);
    const html = $('html');
    const body = $('body');
    const search = dom.find('[data-search]');
    const searchOpen = search.find('g').get(0);
    const searchClose = search.find('g').get(1);
    const hamburger = dom.find('[data-hamburger]');
    const hamburgerTop = hamburger.find('i:nth-of-type(1)').get(0);
    const hamburgerMiddle = hamburger.find('i:nth-of-type(2)').get(0);
    const hamburgerBottom = hamburger.find('i:nth-of-type(3)').get(0);
    const buttonSkip = dom.find('[data-skip]');

    let isActive = true;
    let isHidden = false;
    let isSolid = false;
    let isCollapsed = false;
    let isMenuOpen = false;
    let isSearchOpen = false;
    let lastTop = 0;

    const getLineOffset = () => {
        const style = getComputedStyle(hamburgerTop);
        return hamburgerTop.offsetHeight + parseInt(style.marginBottom);
    };

    const hamburgerToX = () => {
        const offset = getLineOffset();
        gsap.to(hamburgerTop, { duration: 0.15, y: offset, ease: 'sine.in' });
        gsap.to(hamburgerBottom, { duration: 0.15, y: offset * -1, ease: 'sine.in', onComplete: () => {
            gsap.set(hamburgerMiddle, { opacity: 0 });
            gsap.to(hamburgerTop, { duration: 0.2, rotationZ: '45deg', transformOrigin: '50% 50%', ease: 'power3.out' });
            gsap.to(hamburgerBottom, { duration: 0.2, rotationZ: '-45deg', transformOrigin: '50% 50%', ease: 'power3.out' });
        } });
    };

    const xToHamburger = () => {
        gsap.to(hamburgerTop, { duration: 0.1, rotationZ: '0deg', transformOrigin: '50% 50%', ease: 'sine.in' });
        gsap.to(hamburgerBottom, {
            duration: 0.1,
            rotationZ: '0deg',
            transformOrigin: '50% 50%',
            ease: 'sine.in',
            onComplete: () => {
                gsap.set(hamburgerMiddle, { opacity: 1 });
                gsap.to(hamburgerTop, { duration: 0.15, y: 0, ease: 'sine.in', clearProps: 'all' });
                gsap.to(hamburgerBottom, { duration: 0.15, y: 0, ease: 'sine.in', clearProps: 'all' });
            }
        });
    };

    const searchToX = () => {
        gsap.to(searchOpen, { duration: 0.4, opacity: 0, rotationZ: '90deg', transformOrigin: '50% 50%', ease: 'power3.inOut' });
        gsap.to(searchClose, { duration: 0.4, opacity: 1, rotationZ: '0deg', transformOrigin: '50% 50%', ease: 'power3.inOut' });
    };

    const xToSearch = () => {
        gsap.to(searchOpen, { duration: 0.4, opacity: 1, rotationZ: '0deg', transformOrigin: '50% 50%', ease: 'power3.inOut' });
        gsap.to(searchClose, { duration: 0.4, opacity: 0, rotationZ: '-90deg', transformOrigin: '50% 50%', ease: 'power3.inOut' });
    };

    const activate = key => {
        if (key === Events.MENU_CLOSE) {
            isMenuOpen = false;
            hamburger.attr('aria-label', 'Åpne meny');
            xToHamburger();
        } else if (key === Events.SEARCH_CLOSE) {
            isSearchOpen = false;
            search.attr('aria-label', 'Åpne søk');
            xToSearch();
        }
        setTimeout(() => {
            isActive = true;
            if (lastTop > OFFSET_TOP_DOWN) {
                solid();
            }
        }, 50);
    };

    const deactivate = key => {
        isActive = false;
        transparent();
        if (key === Events.MENU_OPEN) {
            isMenuOpen = true;
            hamburger.attr('aria-label', 'Lukk meny');
            hamburgerToX();
        } else if (key === Events.SEARCH_OPEN) {
            isSearchOpen = true;
            search.attr('aria-label', 'Lukk søk');
            searchToX();
        }
    };

    const show = () => {
        if (isActive && isHidden) {
            isHidden = false;
            gsap.killTweensOf(el);
            gsap.to(el, { duration: 0.5, yPercent: 0, ease: 'power1.out' });
        }
    };

    const hide = () => {
        if (isActive && !isHidden) {
            isHidden = true;
            gsap.killTweensOf(el);
            gsap.to(el, {
                duration: 0.5,
                yPercent: -100,
                ease: 'power1.out',
                onComplete: () => {
                    collapse();
                    solid();
                }
            });
        }
    };

    const expand = () => {
        if (isCollapsed) {
            isCollapsed = false;
            body.removeClass('has-collapsed-header');
        }
    };

    const collapse = () => {
        if (!isCollapsed) {
            isCollapsed = true;
            body.addClass('has-collapsed-header');
        }
    };

    const transparent = () => {
        if (isSolid) {
            isSolid = false;
            body.removeClass('has-solid-header');
        }
    };

    const solid = () => {
        if (!isSolid) {
            isSolid = true;
            body.addClass('has-solid-header');
        }
    };

    const scrollHandler = () => {
        const scrollTop = Viewport.scrollTop;
        if (Math.abs(scrollTop - lastTop) > OFFSET_BUFFER) {
            const isScrollingUp = scrollTop < lastTop;

            if (isActive) {
                if (isScrollingUp) {
                    show();
                    if (scrollTop < OFFSET_TOP_DOWN) {
                        expand();
                        transparent();
                    }
                } else if (scrollTop > OFFSET_TOP_UP) {
                    hide();
                }
            }
            lastTop = scrollTop;
        }
    };

    const hideIfPageScrolled = () => {
        if (html.hasClass('scrolled') || Viewport.scrollTop > 0) {
            isHidden = true;
            solid();
            collapse();
            dom.css('transform', 'none');
            gsap.set(el, { yPercent: -100 });
        }
    };

    const skipToContent = e => {
        e.preventDefault();
        $('#main').focus();
    };

    const init = () => {
        search.on('click', e => {
            e.preventDefault();
            if (body.hasClass('is-search')) {
                $('#query_page').focus();
            } else if (isMenuOpen) {
                Dispatch.emit(Events.MENU_CLOSE);
                Dispatch.emit(Events.SEARCH_OPEN);
            } else {
                Dispatch.emit(isSearchOpen ? Events.SEARCH_CLOSE : Events.SEARCH_OPEN);
            }
        });

        hamburger.on('click', e => {
            e.preventDefault();
            if (isSearchOpen) {
                Dispatch.emit(Events.SEARCH_CLOSE);
                Dispatch.emit(Events.MENU_OPEN);
            } else {
                Dispatch.emit(isMenuOpen ? Events.MENU_CLOSE : Events.MENU_OPEN);
            }
        });

        if (searchClose) {
            gsap.set(searchClose, {opacity: 0, rotationZ: '-90deg', transformOrigin: '50% 50%'});
        }

        buttonSkip.on('click', skipToContent);
        dom.on('focusin', show);
        Dispatch.on(Events.MENU_OPEN, deactivate);
        Dispatch.on(Events.MENU_CLOSE, activate);
        Dispatch.on(Events.SEARCH_OPEN, deactivate);
        Dispatch.on(Events.SEARCH_CLOSE, activate);
        Viewport.on('scroll', scrollHandler);

        //hideIfPageScrolled();
    };

    const destroy = () => {
        dom.off('focusin');
        hamburger.off('click');
        search.off('click');
        buttonSkip.off('click', skipToContent);
        Viewport.off('scroll', scrollHandler);
        Dispatch.off(Events.MENU_OPEN, deactivate);
        Dispatch.off(Events.MENU_CLOSE, activate);
        Dispatch.off(Events.SEARCH_OPEN, deactivate);
        Dispatch.off(Events.SEARCH_CLOSE, activate);
    };

    return {
        init,
        destroy
    };
};
