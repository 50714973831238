import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';
import shouldAnimate from "../lib/ReducedMotion";

export default el => {
    const accept = $(el).find('[data-accept]');

    const init = () => {
        accept.on('click', e => {
            window.vrsg.setCookie('cookies-disclaimed', 1, 365);
            if (shouldAnimate()) {
                gsap.to(el, {
                    duration: 1, y: '100%', ease: 'expo.inOut', onComplete: () => {
                        destroy();
                        el.remove();
                    }
                });
            } else {
                destroy();
                el.remove();
            }
        });
    };

    const destroy = () => {
        accept.off('click');
    };

    return {
        init,
        destroy
    };
};
