import $ from '@vaersaagod/tools/Dom';
import agent from '@vaersaagod/tools/request';
import * as Events from '../lib/events';

export default (el, props) => {
    const dom = $(el);
    const field = dom.find('[data-field]');
    const section = dom.find('[data-section]');
    const limit = dom.find('[data-limit]');
    const feedback = dom.find('[data-feedback]');
    const results = dom.find('[data-results]');
    const list = dom.find('[data-list]');

    let request = null;
    let lastQuery = '';

    const cancelSubmit = e => {
        e.preventDefault();
        return false;
    };

    const cancel = () => {
        if (request) {
            request.abort();
            request = null;
        }
    };

    const clear = () => {
        cancel();
        feedback.empty();
        list.empty();
        results.attr('hidden', '');
    };

    const reset = () => {
        clear();
        field.val('');
    };

    const search = () => {
        request = agent.get(`${dom.attr('action')}?q=${field.val().trim()}&s=${(section.val() || '').trim()}&l=${(limit.val() || '').trim()}`);
        request.then(res => {
            const response = $(res.text);
            feedback.html(response.find('[data-feedback]').html());
            list.html(response.find('[data-list]').html());
            results.attr('hidden', null);
        }, error => {
            console.error(error);
        });
    };

    const keyFieldHandler = e => {
        if ((e.keyCode || e.which) === 27) {
            reset();
        } else {
            const query = field.val().trim();
            if (query.length > 2) {
                if (query !== lastQuery) {
                    search();
                }
            } else {
                clear();
            }
            lastQuery = query;
        }
    };

    const init = () => {
        dom.on('submit', cancelSubmit);
        field.on('keyup', keyFieldHandler);
    };

    const destroy = () => {
        dom.off('submit', cancelSubmit);
        field.off('keyup', keyFieldHandler);
        reset();
    };

    return {
        init,
        destroy
    };
};
