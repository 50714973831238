import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';
import shouldAnimate, {onReducedMotion} from "../lib/ReducedMotion";
const loadLottie = require('bundle-loader?lazy&name=[name]!lottie-web');

export default el => {
    const dom = $(el);

    const anims = { left: null, right: null };
    const elements = { left: dom.find('[data-left]'), right: dom.find('[data-right]') };

    let lottie = null;
    let observer = null;
    let shouldPlay = shouldAnimate();

    const onIntersectionChange = entries => {
        if (entries.filter(entry => entry.isIntersecting).length) {
            loadOrPlay('left');
            loadOrPlay('right');
        } else {
            stop('left');
            stop('right');
        }
    };

    const onReducedMotionChange = e => {
        shouldPlay = !e.matches;
        if (shouldPlay) {
            loadOrPlay('left');
            loadOrPlay('right');
        } else {
            stop('left');
            stop('right');
        }
    };

    const stop = key => {
        const animation = anims[key];
        if (animation) {
            animation.pause();
        }
    };

    const loadOrPlay = key => {
        const animation = anims[key];
        const element = elements[key];
        if (animation && shouldPlay) {
            animation.play();
            element.attr('hidden', null);
        } else {
            gsap.set(element.get(0), { opacity: 0 });
            anims[key] = lottie.loadAnimation({
                container: element.get(0),
                renderer: 'svg',
                loop: true,
                autoplay: shouldPlay,
                path: element.data(key)
            });
            anims[key].addEventListener('DOMLoaded', () => {
                element.attr('hidden', null);
                gsap.fromTo(element.get(0), { opacity: 0 }, {duration: 0.35, opacity: 1, ease: 'power1.inOut'})
            });
        }
    };

    const randomInt = (min = 0, max = 1000) => Math.floor(min + (Math.random() * (max - min)));

    const randomFloat = (min = 0, max = 1000) => min + (Math.random() * (max - min));

    const init = () => {
        loadLottie(l => {
            lottie = l;
            observer = new IntersectionObserver(onIntersectionChange);
            observer.observe(el);

            onReducedMotion(onReducedMotionChange);
        });
    };

    const destroy = () => {
        observer.unobserve(el);
    };

    return {
        init,
        destroy
    };
};
