import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';
import shouldAnimate from "../lib/ReducedMotion";

export default el => {
    const close = $(el).find('[data-close]');
    const form = $(el).find('[data-form]');

    const setCookie = () => {
        window.vrsg.setCookie('newsletter-popup', 1, 365);
    };

    const init = () => {
        close.on('click', e => {
            setCookie();
            if (shouldAnimate()) {
                gsap.to(el, {
                    duration: 0.5, bottom: 0, y: '100%', ease: 'back.in', onComplete: () => {
                        destroy();
                        el.remove();
                    }
                });
            } else {
                destroy();
                el.remove();
            }
        });
        form.on('submit', () => {
            setCookie();
        });
    };

    const destroy = () => {
        close.off('click');
        form.off('submit');
    };

    return {
        init,
        destroy
    };
};
