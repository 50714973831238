import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';
import shouldAnimate, { onReducedMotion } from "../lib/ReducedMotion";
const loadLottie = require('bundle-loader?lazy&name=[name]!lottie-web');

export default el => {
    const dom = $(el);
    const element = dom.find('[data-lottie]');

    let lottie = null;
    let observer = null;
    let animation = null;
    let shouldPlay = shouldAnimate();

    const onIntersectionChange = entries => {
        if (animation) {
            if (entries.filter(entry => entry.isIntersecting).length) {
                if (shouldPlay) {
                    animation.play();
                }
            } else {
                animation.pause();
            }
        }
    };

    const onReducedMotionChange = e => {
        shouldPlay = !e.matches;
        if (shouldPlay) {
            animation.play();
        } else {
            animation.pause();
        }
    };

    const init = () => {
        loadLottie(l => {
            lottie = l;
            animation = lottie.loadAnimation({
                container: element.get(0),
                renderer: 'svg',
                loop: true,
                autoplay: shouldPlay,
                path: element.data('lottie')
            });
            animation.addEventListener('DOMLoaded', () => {
                element.attr('hidden', null);
                gsap.fromTo(element.get(0), { opacity: 0 }, {duration: 0.35, opacity: 1, ease: 'power1.inOut'})
            });
            observer = new IntersectionObserver(onIntersectionChange);
            observer.observe(el);

            onReducedMotion(onReducedMotionChange);
        });
    };

    const destroy = () => {
        observer.unobserve(el);
    };

    return {
        init,
        destroy
    };
};
