import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import gsap from 'gsap';
import shouldAnimate from '../lib/ReducedMotion';

export default el => {
    const dom = $(el);
    const list = dom.find('[data-items]').get(0);
    const items = dom.find('[data-items] li').get();
    const progress = dom.find('[data-progress]').get(0);
    const button = dom.find('[aria-expanded]');
    const links = dom.find('[data-items] a');

    const segments = $('body').find('[data-segment]');

    const windowWidth = () => (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth || 0);
    const windowHeight = () => (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight || 0);
    const windowScroll = () => (window.pageYOffset || document.body.scrollTop || document.documentElement.scrollTop || 0);
    const documentHeight = () => Math.max(
        document.body.scrollHeight || 0,
        document.documentElement.scrollHeight || 0,
        document.body.offsetHeight || 0,
        document.documentElement.offsetHeight || 0,
        document.body.clientHeight || 0,
        document.documentElement.clientHeight || 0
    );

    const progressTo = gsap.quickTo(progress, 'width', { duration: 0.4, ease: 'sine.out' });

    let isOpen = false;
    let segmentLookup = null;
    let segmentIndex = -1;

    const updateSegmentLookup = () => {
        segmentLookup = segments.nodes.map(node => {
            const segment = $(node);
            return {
                segment,
                offset: segment.offset().top
            };
        });
    };

    const updateListPosition = () => {
        const offset = items[segmentIndex+1].offsetTop * -1;
        gsap.set(list, {y: offset});
    };

    const updateActiveSegment = () => {
        const scrollOffset = Viewport.scrollTop;
        const halfScreenHeight = Viewport.height / 2;
        let currentIndex = -1;
        segmentLookup.forEach((segment,i) => {
            if (segment.offset < scrollOffset + halfScreenHeight) {
                currentIndex = i;
            }
        });
        if (currentIndex !== segmentIndex) {
            segmentIndex = currentIndex;
            if (!isOpen) {
                updateListPosition();
            }
        } else {
            updateListPosition();
        }
    };

    const updateProgress = () => {
        const percent = windowScroll() / (documentHeight() - windowHeight());
        progressTo(windowWidth() * percent);
    };

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (key === 27) {
            close();
        }
    };

    const open = () => {
        if (!isOpen) {
            isOpen = true;
            document.addEventListener('keyup', keyHandler);
            document.addEventListener('click', clickHandler);
            el.addEventListener('focusout', focusOutHandler);
            gsap.set(list, {y: 0});
            button.attr('aria-expanded', true);
            dom.addClass('-open');
        }
    };

    const close = () => {
        if (isOpen) {
            isOpen = false;
            document.removeEventListener('keyup', keyHandler);
            document.removeEventListener('click', clickHandler);
            el.removeEventListener('focusout', focusOutHandler);
            button.attr('aria-expanded', false);
            dom.removeClass('-open');
            updateListPosition();
        }
    };

    const scrollAndClose = e => {
        const href = e.triggerTarget.getAttribute('href');
        if (href && href.indexOf('#') !== -1) {
            const target = $(href);
            if (target.length) {
                e.preventDefault();
                if (shouldAnimate()) {
                    const offset = target.position().top;
                    gsap.to(window, {
                        duration: 1, scrollTo: {y: offset, autoKill: false}, ease: 'power2.inOut', onComplete: () => {
                            target.get(0).focus();
                        }
                    });
                } else {
                    target.get(0).scrollIntoView(true);
                    target.get(0).focus();
                }
            }
        }
    };

    const toggle = () => {
        if (button.attr('aria-expanded') === 'false') {
            open();
        } else {
            close();
        }
    };

    const clickHandler = e => {
        if (!el.contains(e.target)) {
            close();
        }
    };

    const focusOutHandler = e => {
        if (!el.contains(e.relatedTarget)) {
            close();
        }
    };

    const onScroll = () => {
        updateActiveSegment();
        updateProgress();
        close();
    };

    const onResize = () => {
        updateSegmentLookup();
        updateActiveSegment();
        updateProgress();
    };

    const init = () => {
        updateSegmentLookup();
        button.on('click', toggle);
        links.on('click', scrollAndClose);
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        button.off('click', toggle);
        links.off('click', scrollAndClose);
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
