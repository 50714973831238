import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import agent from '@vaersaagod/tools/request';
import * as Events from '../lib/events';

const MODAL_BODY_CLASS = 'is-modal-open';

export default (el, props) => {
    const dom = $(el);
    const body = $('body');
    const form = dom.find('form');
    const field = dom.find('[data-field]');
    const feedback = dom.find('[data-feedback]');
    const results = dom.find('[data-results]');
    const list = dom.find('[data-list]');
    const popular = dom.find('[data-popular]');

    let request = null;
    let active = false;
    let lastQuery = '';
    let trigger = false;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (active && key === 27) {
            Dispatch.emit(Events.SEARCH_CLOSE);
        }
    };

    const cancelSubmit = e => {
        e.preventDefault();
        return false;
    };

    const cancel = () => {
        if (request) {
            request.abort();
            request = null;
        }
    };

    const clear = () => {
        cancel();
        feedback.empty();
        list.empty();
        results.attr('hidden', '');
        popular.attr('hidden', null);
    };

    const search = () => {
        request = agent.get(`${form.attr('action')}?q=${field.val().trim()}`);
        request.then(res => {
            const response = $(res.text);
            feedback.html(response.find('[data-feedback]').html());
            list.html(response.find('[data-list]').html());
            results.attr('hidden', null);
            popular.attr('hidden', '');
        }, error => {
            console.error(error);
        });
    };

    const keyFieldHandler = () => {
        const query = field.val().trim();
        if (query.length > 2) {
            if (query !== lastQuery) {
                search();
            }
        } else {
            clear();
        }
        lastQuery = query;
    };

    const onOpen = () => {
        if (!active) {
            active = true;
            trigger = document.activeElement;
            document.addEventListener('keyup', keyHandler);
            gsap.set(el, { display: 'block', opacity: 0 });
            field.on('keyup', keyFieldHandler).focus();
            body.addClass(MODAL_BODY_CLASS);
            gsap.to(el, { duration: 0.35, opacity: 1, ease: 'power1.inOut' });
            Viewport.lockTabbing([el, $('.page-header__search').get(0)], field);
        }
    };

    const onClose = () => {
        if (active) {
            active = false;
            document.removeEventListener('keyup', keyHandler);
            body.removeClass(MODAL_BODY_CLASS);
            gsap.to(el, { duration: 0.15, opacity: 0, ease: 'power1.out', clearProps: 'all' });
            Viewport.releaseTabbing(trigger);
        }
    };

    const init = () => {
        form.on('submit', cancelSubmit);
        Dispatch.on(Events.SEARCH_OPEN, onOpen);
        Dispatch.on(Events.SEARCH_CLOSE, onClose);
    };

    const destroy = () => {
        form.off('submit', cancelSubmit);
        Dispatch.off(Events.SEARCH_OPEN, onOpen);
        Dispatch.off(Events.SEARCH_CLOSE, onClose);
    };

    return {
        init,
        destroy
    };
};
