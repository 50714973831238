import $ from "@vaersaagod/tools/Dom";
import gsap from "gsap";
import shouldAnimate from "./ReducedMotion";

const clickAndScroll = e => {
    const href = e.triggerTarget.getAttribute('href');
    if (href && href.indexOf('#') !== -1) {
        const target = $(href);
        if (target.length) {
            e.preventDefault();
            if (shouldAnimate()) {
                const prev = target.prev();
                const offset = prev.length ? prev.position().top + prev.height() : target.position().top - parseInt(target.css('marginBottom'));
                gsap.to(window, {
                    duration: 1, scrollTo: {y: offset, autoKill: false}, ease: 'power2.inOut', onComplete: () => {
                        target.get(0).focus();
                    }
                });
            } else {
                if (href === '#top') {
                    window.scrollTo(0, 0);
                } else {
                    target.get(0).scrollIntoView(true);
                }
                target.get(0).focus();
            }
        }
    }
};

export default clickAndScroll;
