import $ from '@vaersaagod/tools/Dom';

export default el => {
    const button = $(el).find('[aria-expanded]');
    const dialog = $(el).find('[hidden]');
    const links = dialog.find('a');

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (key === 27) {
            close();
        }
    };

    const open = () => {
        document.addEventListener('keyup', keyHandler);
        document.addEventListener('click', clickHandler);
        el.addEventListener('focusout', focusOutHandler);
        button.attr('aria-expanded', true);
        dialog.attr('hidden', null);
    };

    const close = () => {
        document.removeEventListener('keyup', keyHandler);
        document.removeEventListener('click', clickHandler);
        el.removeEventListener('focusout', focusOutHandler);
        button.attr('aria-expanded', false);
        dialog.attr('hidden', '');
    };

    const toggle = e => {
        if (button.attr('aria-expanded') === 'false') {
            open();
        } else {
            close();
        }
    };

    const clickHandler = e => {
        if (!el.contains(e.target)) {
            close();
        }
    };

    const focusOutHandler = e => {
        if (!el.contains(e.relatedTarget)) {
            close();
        }
    };

    const init = () => {
        button.on('click', toggle);
    };

    const destroy = () => {
        button.off('click', toggle);
    };

    return {
        init,
        destroy
    };
};
