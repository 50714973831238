import './lib/polyfills';
import lazySizes from './lib/lazysizes';
import ViewportHeight from './components/ViewportHeight';
import Viewport from '@vaersaagod/tools/Viewport';
import Components from '@vaersaagod/tools/Components';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.defaults({ overwrite: 'auto' });
gsap.registerPlugin(ScrollToPlugin);


/*
*   Bootstrap app
*
 */
const init = () => {
    Viewport.init();
    Viewport.releaseTabbing();
    ViewportHeight.init();
    Components.init();
    lazySizes.init();
};

require('doc-ready')(init);
