const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce), (update: slow)');
const shouldAnimate = () => !mediaQuery.matches;

export const onReducedMotion = callback => {
    mediaQuery.addEventListener('change', e => {
        callback(e);
    });
};

export default shouldAnimate;
