import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const button = dom.find('[aria-expanded]');
    const dialog = dom.find('[data-dialog]');
    const prev = dom.find('[data-prev]');
    const next = dom.find('[data-next]');
    const dialogMobile = dom.parent('[data-hotspots]').next();
    const dialogMobileNumber = dialogMobile.find('[data-mobile-dialog-number]');
    const dialogMobileContent = dialogMobile.find('[data-mobile-dialog-content]');
    const dialogMobileClose = dialogMobile.find('[data-mobile-dialog-close]');

    const hotspots = dom.parent().find('.hotspot');

    let active = false;
    let direction = 'right';

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (active && key === 27) {
            Dispatch.emit(Events.HOTSPOT_CLOSE);
        }
    };

    const close = () => {
        if (active) {
            active = false;
            document.removeEventListener('keyup', keyHandler);
            dom.removeClass('js-is-active');
            button.attr('aria-expanded', false).attr('aria-label', button.data('label'));
            dialog.attr('hidden', '');
            dialogMobile.attr('hidden', '');
        }
    };

    const open = (key, target) => {
        if (target !== el && active) {
            console.log('OPEN: not me, so close', target);
            close();
        } else if (target === el && !active) {
            console.log('OPEN: yep me, so open', target);
            active = true;
            document.addEventListener('keyup', keyHandler);
            dom.addClass('js-is-active');
            button.attr('aria-expanded', true).attr('aria-label', button.data('close'));
            dialog.attr('hidden', null);
            dialogMobileContent.html(dialog.html());
            dialogMobileNumber.text(button.text());
            dialogMobile.attr('hidden', null);
        }
    };

    const toggle = e => {
        e.preventDefault();
        Dispatch.emit(button.attr('aria-expanded') === 'false' ? Events.HOTSPOT_OPEN : Events.HOTSPOT_CLOSE, el);
    };

    const resize = () => {
        dialog.removeClass('left');
        direction = dialog.get(0).getBoundingClientRect().right > Viewport.width - 20 ? 'left' : 'right';
        dialog.toggleClass('left', direction === 'left');
    };

    const onDialogMobileClose = () => {
        if(active) {
            button.focus();
        }
        Dispatch.emit(Events.HOTSPOT_CLOSE);
    };

    const triggerByIndex = index => {
        const target = hotspots.eq(index).get(0);
        if (target) {
            console.log('triggerByIndex', target);
            Dispatch.emit(Events.HOTSPOT_OPEN, target);
        }
    };

    const onNext = () => {
        let index = hotspots.index(el) + 1;
        if (index > hotspots.length - 1) {
            index = 0;
        }
        triggerByIndex(index);
    };

    const onPrev = () => {
        let index = hotspots.index(el) - 1;
        if (index < 0) {
            index = hotspots.length - 1;
        }
        triggerByIndex(index);
    };

    const init = () => {
        prev.on('click', onPrev);
        next.on('click', onNext);
        button.data('label', button.attr('aria-label'));
        button.on('click', toggle);
        dialogMobileClose.on('click', onDialogMobileClose);
        Dispatch.on(Events.HOTSPOT_OPEN, open);
        Dispatch.on(Events.HOTSPOT_CLOSE, close);
        Viewport.on('resize', resize);
        resize();
    };

    const destroy = () => {
        prev.off('click', onPrev);
        next.off('click', onNext);
        button.off('click', toggle);
        dialogMobileClose.off('click', onDialogMobileClose);
        Dispatch.off(Events.HOTSPOT_OPEN, open);
        Dispatch.off(Events.HOTSPOT_CLOSE, close);
        Viewport.off('resize', resize);
    };

    return {
        init,
        destroy
    };
};
