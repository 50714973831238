import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';

export default (el, props) => {

    const button = $(el).find('[data-download]');

    const onClick = e => {
        const event = `Diplom nedlasting: ${document.title.split('–')[0].trim()}`;
        console.log(event)
        if (typeof fathom !== 'undefined') {
            fathom.trackEvent(event);
        }
    };

    const destroy = () => {
        button.off('click', onClick);
    };

    const init = () => {
        button.on('click', onClick);
    };

    return {
        init,
        destroy
    };

};
