import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';

import clickAndScroll from '../lib/clickAndScroll';

export default el => {
    const dom = $(el);
    const links = dom.find('a');


    const init = () => {
        links.on('click', clickAndScroll);
    };

    const destroy = () => {
        links.off('click', clickAndScroll);
    };

    return {
        init,
        destroy
    };
};
