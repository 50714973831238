import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Flickity from 'flickity';

export default (el, props) => {
    const dom = $(el);
    const wrapper = dom.find('[data-slides]').get(0);
    let flkty = null;

    const init = () => {
        flkty = new Flickity(wrapper, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: true,
            pageDots: false,
            wrapAround: true,
            freeScroll: true,
            freeScrollFriction: 0.045,
            arrowShape: '',
            groupCells: true,
            on: {
                ready: function() {
                    this.prevButton.element.setAttribute('aria-label', 'Forrige');
                    this.nextButton.element.setAttribute('aria-label', 'Neste');
                }
            }
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });

        flkty.on('select', () => {
            flkty.cells.forEach(({ element }) => {
                element.removeAttribute('aria-hidden');
            });
        });
    };

    const destroy = () => {
        flkty.destroy();
    };

    return {
        init,
        destroy
    };
};
