import $ from '@vaersaagod/tools/Dom';
import Viewport from '@vaersaagod/tools/Viewport';
import Flickity from 'flickity';

export default (el, props) => {
    const dom = $(el);
    const wrapper = dom.find('[data-slides]').get(0);
    const slides = dom.find('[data-slide]');
    let flkty = null;

    const initFlickity = () => {
        flkty = new Flickity(wrapper, {
            contain: true,
            dragThreshold: 15,
            cellAlign: 'left',
            prevNextButtons: true,
            pageDots: false,
            freeScroll: true,
            freeScrollFriction: 0.045,
            arrowShape: '',
            groupCells: true,
            on: {
                ready: function() {
                    this.prevButton.element.setAttribute('aria-label', 'Forrige');
                    this.nextButton.element.setAttribute('aria-label', 'Neste');
                }
            }
        });

        flkty.on('dragStart', () => {
            document.ontouchmove = e => e.preventDefault();
        });

        flkty.on('dragEnd', () => {
            document.ontouchmove = () => true;
        });

        flkty.on('select', () => {
            flkty.cells.forEach(({ element }) => {
                element.removeAttribute('aria-hidden');
            });
        });

        slides.on('focusin', e => {
            wrapper.parentNode.scrollLeft = 0;
            const { triggerTarget: link } = e;
            const cell = flkty.cells.find(({ element }) => element.contains(link));
            if (!cell) {
                return;
            }
            const index = flkty.cells.indexOf(cell);
            if (index > -1) {
                flkty.selectCell(index);
            }
        });
    };

    const destroyFlickity = () => {
        if (flkty) {
            flkty.destroy();
            flkty = null;
        }
    };

    const resize = () => {
        if (props && props.cutoff) {
            if (Viewport.width < props.cutoff && !flkty) {
                initFlickity();
            } else if (Viewport.width >= props.cutoff && flkty) {
                destroyFlickity();
            }
        } else {
            initFlickity();
        }
        if (flkty) {
            adjustPrevNextButtons();
        }
    };

    const adjustPrevNextButtons = () => {
        const images = dom.find('img').nodes.sort((a, b) => {
            if (a.height < b.height) {
                return -1;
            } else if (a.height > b.height) {
                return 1;
            }
            return 0;
        });
        const offset = Math.round(images[0].height / 2);
        $([flkty.prevButton.element,flkty.nextButton.element]).css('top', offset + 'px');
    };

    const init = () => {
        Viewport.on('resize', resize);
        resize();
    };

    const destroy = () => {
        destroyFlickity();
        Viewport.off('resize');
    };

    return {
        init,
        destroy
    };
};
