import $ from '@vaersaagod/tools/Dom';

export default el => {
    const dom = $(el);
    const button = dom.find('[data-submit]');
    const recaptcha = dom.data('recaptcha') || false;
    const recaptchaToken = dom.find('[name="token"]');

    let isSubmitted = false;

    const onSubmit = e => {
        e.preventDefault();
        if (!isSubmitted) {
            isSubmitted = true;
            button.attr('disabled', '');
            const { grecaptcha } = window;
            if (recaptcha && grecaptcha) {
                grecaptcha.ready(() => {
                    grecaptcha.execute(recaptcha, { action: 'submit' }).then(token => {
                        recaptchaToken.val(token);
                        dom.off('submit', onSubmit);
                        el.submit();
                    });
                });
            } else {
                el.submit();
            }
            return true;
        }
        return false;
    };

    const init = () => {
        dom.on('submit', onSubmit);
    };

    const destroy = () => {
        dom.off('submit', onSubmit);
    };

    return {
        init,
        destroy
    };
};
