import $ from '@vaersaagod/tools/Dom';
import gsap from 'gsap';
import shouldAnimate from '../lib/ReducedMotion';
import Viewport from "@vaersaagod/tools/Viewport";

export default el => {
    const dom = $(el);
    const next = dom.find('[data-next]');
    const prev = dom.find('[data-prev]');

    const getOffsetY = element => {
        const rect = element.getBoundingClientRect();
        return rect.top + document.documentElement.scrollTop;
    }

    const getHeaderHeight = () => {
        return document.body.querySelector('.page-header').getBoundingClientRect().height;
    };

    const scrollToElement = (element, offset = 0, duration = 1) => {
        if (!element) return;

        if (shouldAnimate()) {
            const position = getOffsetY(element) + offset;
            gsap.to(window, {
                duration: duration, scrollTo: {y: position, autoKill: false}, ease: 'power2.inOut', onComplete: () => {
                    //element.focus();
                }
            });
        } else {
            element.scrollIntoView(true);
            element.focus();
        }
    }

    const onPrev = e => {
        let prevSibling = el.previousElementSibling;
        while(prevSibling) {
            prevSibling = prevSibling.previousElementSibling;
            if (prevSibling && prevSibling.hasAttribute('data-segment')) {
                scrollToElement(prevSibling, getHeaderHeight() * -1);
                break;
            }
        }

    };

    const onNext = e => {
        let nextSibling = el.nextElementSibling;
        scrollToElement(nextSibling, 0, 0.75);
    };

    const init = () => {
        next.on('click', onNext);
        prev.on('click', onPrev);
    };

    const destroy = () => {
        next.off('click', onNext);
        prev.off('click', onPrev);
    };

    return {
        init,
        destroy
    };
};
